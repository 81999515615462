import React from 'react';
import './navBar.scss';

const NavBar = (): JSX.Element => (
  <nav className="navbar navbar-expand-lg navbar-blue fixed-top">
    <div className="container justify">
      <a className="navbar-brand" href="#werw">
        <img src="quro_logo.png" className="img-logo" alt="Logo" />
      </a>
      <div className="logoBlue quro-heading">Quro</div>

      <div className=" logoGrey quro-heading">Medical</div>
    </div>
  </nav>
);
export default NavBar;
