import React from 'react';
import success from './success.png';

export default function SucessMessage(): JSX.Element {
  return (
    <div className="response-content">
      <span>
        <img style={{ verticalAlign: 'middle', height: '10vh' }} src={success} alt="success-icon" />
        <span style={{ verticalAlign: 'middle', fontSize: '1.5em', fontWeight: 'bold' }}>
          {' '}
          Successfully registered.
        </span>
        <p>You can now close this page.</p>
      </span>
    </div>
  );
}
