import React from 'react';
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom"
import ConsentForm from './ConsentForm';
import TCPForm from './TCPForm';

const router = createBrowserRouter([
  {
    path: "/",
    element: <ConsentForm />,
  },
  {
    path: "/tcp",
    element: <TCPForm />,
  },
]);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
