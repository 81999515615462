import React from 'react';
import './loader.scss';

export default function Loader(): JSX.Element {
  return (
    <div className="container" id="loader">
      <img src="loader.gif" id="gif" alt="Loading-gif" />
    </div>
  );
}
