import React, { useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import saIdParser from 'south-african-id-parser';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';
import NavBar from './Components/NavBar';
import Loader from './Components/Loader/Loader';
import { InformationText, ConsentText } from './TCPFormText';
import SuccessMessage from './Components/SuccessMessage/SuccessMessage';

function TCPForm(): JSX.Element {
  const [inputs, setInputs] = useState({
    dateOfBirth: '',
    gender: '',
    signedConsent: false,
    AnyOperation: 'false',
    account_person_respsonsible_contact_number: '',
    account_person_respsonsible_email: '',
    account_person_respsonsible_firstname: '',
    account_person_respsonsible_lastname: '',
    account_person_respsonsible_relationship: '',
    anyKnownAllergies: 'false',
    cellphoneNumber: '',
    citizenship: 'yes',
    day: '',
    emailAddress: '',
    fullNames: '',
    havePaceMaker: 'false',
    homeLanguage: '',
    homeTelephoneNumber: '',
    idnumber: '',
    idnumberIsValid: true,
    initials: '',
    knownAllergies: '',
    mainMemberCell: '',
    mainMemberName: '',
    mainMemberOrDependent: '',
    mainMemberSurname: '',
    medicalScheme: '',
    medicalSchemeNo: '',
    medicalSchemePlanOption: '',
    medical_scheme_or_cash: '',
    medicationTaken: '',
    nextOfKinContactNumber: '',
    nextOfKinFullNames: '',
    nextOfKinSurname: '',
    next_of_kin_title: '',
    otherHomeLanguage: '',
    physicalAddress: '',
    postalAddress: '',
    referringDoctorFullNames: '',
    relationshipToPatient: '',
    responsibleForAccount: '',
    surname: '',
    takingAnyMedication: 'false',
    title: '',
    validationErrors: false,
    isLoading: false,
    requestError: false,
    successfullySubmitted: false,
  });

  const [validated, setValidated] = useState(false);

  function scrollToTop() {
    const element = document.getElementsByClassName('firstField')[0];
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' }); // Top
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (
      form.checkValidity() === false ||
      (!inputs.idnumberIsValid && inputs.citizenship === 'yes')
    ) {
      setInputs({ ...inputs, validationErrors: true });
      event.stopPropagation();
      scrollToTop();
    } else {
      setInputs({ ...inputs, isLoading: true });
      axios
        .post('/api/', inputs)
        .then((response) => {
          setInputs({ ...inputs, isLoading: false, successfullySubmitted: true });
        })
        .catch(() => {
          setInputs({ ...inputs, requestError: true });
        });
    }

    setValidated(true);
  };

  const handleInputChange = (event: any) => {
    event.persist();
    setInputs((newInputs) => ({
      ...newInputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePersonResponsibleForAccount = (event: any) => {
    const { name, value } = event.target;
    if (value === 'yes') {
      setInputs((newInputs) => ({
        ...newInputs,
        account_person_respsonsible_contact_number: newInputs.cellphoneNumber,
        account_person_respsonsible_email: newInputs.emailAddress,
        account_person_respsonsible_firstname: newInputs.fullNames,
        account_person_respsonsible_lastname: newInputs.surname,
        account_person_respsonsible_relationship: "I'm the patient",
      }));
    } else {
      setInputs((newInputs) => ({
        ...newInputs,
        account_person_respsonsible_contact_number: '',
        account_person_respsonsible_email: '',
        account_person_respsonsible_firstname: '',
        account_person_respsonsible_lastname: '',
        account_person_respsonsible_relationship: '',
      }));
    }
    event.persist();
    setInputs((newInputs) => ({
      ...newInputs,
      [name]: value,
    }));
  };

  const handleCheckBoxInputChange = (event: any) => {
    event.persist();
    setInputs((newInputs) => ({
      ...newInputs,
      [event.target.name]: event.target.checked,
    }));
  };

  // TODO: Change to dateOfBirth.toLocaleDateString()
  const formatDateOfBirth = (dateOfBirth: Date) =>
    `${dateOfBirth.getDate()}/${dateOfBirth.getMonth() + 1}/${dateOfBirth.getFullYear()}`;

  const validateId = () => {
    const parsedIdNumber = saIdParser.parse(inputs.idnumber);
    if (parsedIdNumber.isValid) {
      setInputs({
        ...inputs,
        dateOfBirth: formatDateOfBirth(parsedIdNumber.dateOfBirth),
        gender: parsedIdNumber.isFemale ? 'Female' : 'Male',
        idnumberIsValid: parsedIdNumber.isValid,
      });
    } else {
      setInputs({
        ...inputs,
        idnumberIsValid: parsedIdNumber.isValid,
      });
    }
  };

  const getForm = () => (
    <>
      <InformationText />
      {inputs.validationErrors ? (
        <h4 className="errors">Please fix the errors below and resubmit</h4>
      ) : (
        ''
      )}
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Form.Group controlId="validationCustom01" className="firstField">
          <Form.Label>Title</Form.Label>
          <Form.Control as="select" name="title" onChange={handleInputChange} required>
            <option hidden value="">
              Select your title
            </option>
            <option>Ms</option>
            <option>Mr</option>
            <option>Dr</option>
            <option>Other</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Full Name(s)</Form.Label>
          <Form.Control
            type="text"
            name="fullNames"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Initial</Form.Label>
          <Form.Control
            type="text"
            name="initials"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Surname</Form.Label>
          <Form.Control
            type="text"
            name="surname"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Are you a South African Citizen?</Form.Label>
          <Form.Check
            type="radio"
            name="citizenship"
            label="Yes"
            value="yes"
            onChange={handleInputChange}
            defaultChecked
            required
          />
          <Form.Check
            type="radio"
            name="citizenship"
            label="No"
            value="No"
            onChange={handleInputChange}
          />
        </Form.Group>
        {inputs.citizenship === 'yes' ? (
          <>
            <Form.Group>
              <Form.Label>ID/Passport Number</Form.Label>
              <Form.Control
                type="text"
                name="idnumber"
                onChange={handleInputChange}
                onBlur={validateId}
                isInvalid={!inputs.idnumberIsValid}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                ID number provided is not valid.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="text"
                name="dateOfBirth"
                onChange={handleInputChange}
                value={inputs.dateOfBirth}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>
          </>
        ) : (
          <>
            <Form.Group>
              <Form.Label>ID/Passport Number</Form.Label>
              <Form.Control
                type="text"
                name="idnumber"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Row>
              <Col sm={12}>
                <Form.Label>Date of Birth</Form.Label>
              </Col>
              <Col>
                <Form.Label>Day</Form.Label>
                <Form.Control
                  type="text"
                  name="day"
                  onChange={handleInputChange}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Month</Form.Label>
                <Form.Control
                  type="text"
                  name="month"
                  onChange={handleInputChange}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Year</Form.Label>
                <Form.Control
                  type="text"
                  name="year"
                  onChange={handleInputChange}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </Col>
            </Form.Row>
          </>
        )}

        <Form.Group>
          <Form.Label>Sex</Form.Label>
          <Form.Control
            value={inputs.gender}
            as="select"
            name="gender"
            onChange={handleInputChange}
            required
          >
            <option value="" hidden>
              Select option:
            </option>
            <option id="genderFemale">Female</option>
            <option id="genderMale">Male</option>
            <option id="genderOther">Other</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Home Language</Form.Label>
          <Form.Control as="select" name="homeLanguage" onChange={handleInputChange} required>
            <option value="">Select Language</option>
            <option value="Afrikaans">Afrikaans</option>
            <option value="English">English</option>
            <option value="Ndebele">Ndebele</option>
            <option value="Northern Sotho">Northern Sotho</option>
            <option value="Sotho">Sotho</option>
            <option value="SiSwati">SiSwati</option>
            <option value="Tsonga">Tsonga</option>
            <option value="Tswana">Tswana</option>
            <option value="Venda">Venda</option>
            <option value="Xhosa">Xhosa</option>
            <option value="Zulu">Zulu</option>
            <option value="Other">Other</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        {inputs.homeLanguage === 'Other' && (
          <Form.Group>
            <Form.Control
              type="text"
              name="otherHomeLanguage"
              placeholder="Enter Language"
              onChange={handleInputChange}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label>Home Telephone number</Form.Label>
          <Form.Control
            type="number"
            name="homeTelephoneNumber"
            onChange={handleInputChange}
            minLength={10}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            Home telephone must be at least 10 digits
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Cellphone number </Form.Label>
          <Form.Control
            type="number"
            name="cellphoneNumber"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Email Address </Form.Label>
          <Form.Control
            type="email"
            name="emailAddress"
            onChange={handleInputChange}
            pattern="^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$"
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">Invalid Email address</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Physical Address</Form.Label>
          <Form.Control
            type="text"
            name="physicalAddress"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label> Postal Address</Form.Label>
          <Form.Control
            type="text"
            name="postalAddress"
            onChange={handleInputChange}
          ></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Are you the person responsible for the account?</Form.Label>
          <Form.Check
            type="radio"
            name="responsibleForAccount"
            value="yes"
            label="Yes"
            onChange={handlePersonResponsibleForAccount}
            required
          />

          <Form.Check
            type="radio"
            name="responsibleForAccount"
            value="no"
            label="No"
            onChange={handlePersonResponsibleForAccount}
            required
          />
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>
        {inputs.responsibleForAccount === 'no' && (
          <>
            <h4>Person Responsible For Account details:</h4>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="account_person_respsonsible_firstname"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="account_person_respsonsible_lastname"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Contact number</Form.Label>
              <Form.Control
                type="number"
                name="account_person_respsonsible_contact_number"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="account_person_respsonsible_email"
                onChange={handleInputChange}
                pattern="/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/"
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">Invalid Email address</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Relation to you</Form.Label>
              <Form.Control
                type="text"
                name="account_person_respsonsible_relationship"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>
          </>
        )}
        <Form.Group>
          <Form.Label>Are you a Medical Scheme or Cash paying patient?</Form.Label>
          <Form.Check
            type="radio"
            name="medical_scheme_or_cash"
            value="medical_scheme"
            label="Medical Scheme"
            onChange={handleInputChange}
            required
          />

          <Form.Check
            type="radio"
            name="medical_scheme_or_cash"
            value="cash"
            label="Cash"
            onChange={handleInputChange}
            required
          />
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        {inputs.medical_scheme_or_cash === 'medical_scheme' && (
          <>
            <Form.Group>
              <Form.Label>Main member or dependent?</Form.Label>
              <Form.Check
                type="radio"
                name="mainMemberOrDependent"
                value="true"
                label="Main Member"
                onChange={handleInputChange}
                required
              />

              <Form.Check
                type="radio"
                name="mainMemberOrDependent"
                value="false"
                label="Dependent"
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {inputs.mainMemberOrDependent === 'false' && (
              <>
                <Form.Group>
                  <Form.Label>{"Main member's Name"}</Form.Label>
                  <Form.Control
                    type="text"
                    name="mainMemberName"
                    onChange={handleInputChange}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>{"Main member's Surname"}</Form.Label>
                  <Form.Control
                    type="text"
                    name="mainMemberSurname"
                    onChange={handleInputChange}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>{"Main member's Cell phone number"}</Form.Label>
                  <Form.Control
                    type="number"
                    name="mainMemberCell"
                    onChange={handleInputChange}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}
            <Form.Group>
              <Form.Label> Medical Scheme</Form.Label>
              <Form.Control
                type="text"
                name="medicalScheme"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label> Medical Scheme no.</Form.Label>
              <Form.Control
                type="text"
                name="medicalSchemeNo"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Medical Scheme Plan Option</Form.Label>
              <Form.Control
                type="text"
                name="medicalSchemePlanOption"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </Form.Group>
          </>
        )}
        <Form.Group>
          <Form.Label> Referring Physician (Full names)</Form.Label>
          <Form.Control
            type="text"
            name="referringDoctorFullNames"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>{"Next of Kin's Title"}</Form.Label>
          <Form.Control as="select" name="next_of_kin_title" onChange={handleInputChange} required>
            <option value="" hidden>
              Select title
            </option>
            <option>Ms</option>
            <option>Mr</option>
            <option>Dr</option>
            <option>Other</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label> Next of Kin (Full names)</Form.Label>
          <Form.Control
            type="text"
            name="nextOfKinFullNames"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>{"Next of Kin's Surname"}</Form.Label>
          <Form.Control
            type="text"
            name="nextOfKinSurname"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Relationship to Patient</Form.Label>
          <Form.Control
            type="text"
            name="relationshipToPatient"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>{"Next of Kin's Contact Number"}</Form.Label>
          <Form.Control
            type="number"
            name="nextOfKinContactNumber"
            onChange={handleInputChange}
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label> Any known allergies?</Form.Label>
          <Form.Check
            type="radio"
            name="anyKnownAllergies"
            value="false"
            label="No"
            defaultChecked
            onChange={handleInputChange}
          />

          <Form.Check
            type="radio"
            name="anyKnownAllergies"
            value="true"
            label="Yes"
            onChange={handleInputChange}
          />
          {inputs.anyKnownAllergies === 'true' && (
            <>
              <Form.Control
                placeholder="Please enter allergies"
                type="textarea"
                name="knownAllergies"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>Are you taking any medication(s)?</Form.Label>
          <Form.Check
            type="radio"
            name="takingAnyMedication"
            value="false"
            label="No"
            defaultChecked
            onChange={handleInputChange}
          />

          <Form.Check
            type="radio"
            name="takingAnyMedication"
            value="true"
            label="Yes"
            onChange={handleInputChange}
          />
          {inputs.takingAnyMedication === 'true' && (
            <>
              <Form.Control
                type="textarea"
                name="medicationTaken"
                placeholder="Please enter the medication you are taking"
                onChange={handleInputChange}
                required
              ></Form.Control>
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            </>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label> Have you had any recent chest operations?</Form.Label>
          <Form.Check
            type="radio"
            name="AnyOperation"
            value="false"
            label="No"
            defaultChecked
            onChange={handleInputChange}
          />

          <Form.Check
            type="radio"
            name="AnyOperation"
            value="true"
            label="Yes"
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label> Do you have a pacemaker?</Form.Label>
          <Form.Check
            type="radio"
            name="havePaceMaker"
            value="false"
            label="No"
            defaultChecked
            onChange={handleInputChange}
          />

          <Form.Check
            type="radio"
            name="havePaceMaker"
            value="true"
            label="Yes"
            onChange={handleInputChange}
          />
        </Form.Group>

        <hr />
        <ConsentText />
        <hr />

        <Form.Group>
          <Form.Check
            type="checkbox"
            name="signedConsent"
            checked={inputs.signedConsent}
            onChange={handleCheckBoxInputChange}
            label="By submitting you are giving us your consent."
            required
          />
          <Form.Control.Feedback type="invalid">
            You need to check before you can submit
          </Form.Control.Feedback>
        </Form.Group>

        <Button disabled={inputs.signedConsent === false} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );

  const getContent = () => {
    if (inputs.isLoading) {
      return <Loader />;
    }

    if (inputs.requestError) {
      return (
        <h4 className="errors">
          Error occured on Server, try again. If problem persist contact Quro Medical Admin
        </h4>
      );
    }

    if (inputs.successfullySubmitted) {
      return <SuccessMessage />;
    }

    return getForm();
  };

  return (
    <div className="App">
      <div
        className={'container quro-web-content'}
        style={{ position: 'relative', top: '110px', marginBottom: '160px' }}
      >
        <NavBar />

        {getContent()}
      </div>
    </div>
  );
}

export default TCPForm;
