import React from 'react';

const InformationText = (): JSX.Element => (
  <>
    <h2>NEW USER INFORMED CONSENT FORM</h2>
    <h3>
      <u>INTRODUCTION</u>
    </h3>
    <p>
      You are about to consent to enrolment on the Quro Medical (Pty) Ltd (<b>Quro Medical</b>)
      Remote Monitoring Platform (the <b>Platform</b>). Before you consent, it is important for you
      to understand what the monitoring will involve and how your data will be treated. Below is a
      detailed informed consent form, covering all the important aspects of the Platform.
    </p>
    <p>
      Please ask us if there is anything that is not clear or if you would like more information.
      This consent is provided voluntarily and will allow for improved monitoring and care during
      your stay in hospital. Should you not consent we will be unable to use this technology for
      your care in hospital. Thank you for reading this informed consent form.{' '}
    </p>
    <h3>
      <u>INFORMATION</u>
    </h3>
    <h4>What will happen if I consent to enrolment on the Platform?</h4>
    <p>You will receive a copy of this informed consent form, which you can keep.</p>
    <p>
      After you have submitted the consent form, we will ask you to complete a questionnaire about
      your personal particulars and your health, as well as details about any medications you may be
      taking. After you have signed the consent form, we may need to review your medical notes from
      your referring physician so that we can confirm the medical details of any suspected health
      problems and medications that you tell us about. We would like your permission to do this.
    </p>
    <p>
      The Platform will coordinate the monitoring of various physiological parameters which we will
      collect from you using various wearable devices. The decision about which wearable device(s)
      are appropriate for use in your individual case will be made by your referring physician.{' '}
    </p>
    The Platform makes use of the following wearable devices:
    <ul>
      <li>
        The VitalPatch®, a small, smart device that will be attached to your chest which will
        collect and send vital sign information about your physiological parameters continuously to
        a cell phone which we will loan to you. The VitalPatch® is disposal, with a battery life of
        5 days. In light of this, the VitalPatch® must be changed every 5 (five) days if you are
        enrolled on the Platform for longer than 5 (five)days. The position of the device may need
        to be changed to avoid irritation. You will be also be provided with a smartphone. The
        smartphone will be connected to the VitalPatch® and the monitoring process will begin. The
        VitalPatch® will automatically collect the following information: heart rate, respiration,
        electrocardiogram (ECG), heart rate variability, temperature, body posture, and footsteps.{' '}
      </li>
      <li>
        We may also provide you with additional monitoring devices including Bluetooth-enabled
        Oximeter to monitor your blood oxygen levels and/or blood pressure monitor
      </li>
    </ul>
    <p>
      During the period of your enrolment on the Platform, it will be necessary to share your data
      (historical and real – time) with your referring physician to enable continuous clinical
      oversight. We would like your permission to do this. Despite your enrolment on the Platform,
      your referring physician will continue to be responsible for all decisions related to your
      clinical management, including decisions about any medications that need to be administered
      and whether or not you should be transferred to a hospital or other health establishment
      during the enrolment period.{' '}
    </p>
    <p>
      Upon completion of your enrolment period, we will ask you to complete a short survey about
      your experience with the wearable devices that were used during your enrolment period as well
      as our service and we will ask you to return the phone and other devices where applicable.{' '}
    </p>
    <h4>What is the aim of the monitoring Platform?</h4>
    <p>
      The Platform enables the monitoring and visualisation of several physiological parameters 24/7
      in real time. This information will give your referring physician some insight into possible
      early clinical deterioration and has the potential to facilitate early intervention in certain
      cases. Please note that although the Platform may aid your referring physician’s decisions
      about diagnosis and treatment, it is <b>not</b> in and of itself
      <b>intended to be a diagnostic or curative tool</b>. Your referring physician will continue to
      be responsible for determining any diagnosis and/or prescribing treatment.{' '}
    </p>
    <h4>Does the monitoring Platform replace my referring physician?</h4>
    <p>
      No. Your physician will continue to be responsible for your clinical oversight and your
      clinical care will continue as normal. Save for facilitating access to the data that we
      collect, we will not interfere with your referring physician’s decisions regarding your
      clinical management.
    </p>
    <h4>Are there any risks associated with the wearable devices?</h4>
    <p>
      The risks that are associated with use of the wearables are expected to be minimal. The
      VitalPatch® may cause skin irritation which disappears without treatment.
    </p>
    <h4>Can I withhold or withdraw my consent?</h4>
    <p>
      Yes, you may withhold or withdraw your consent at any time, without having to tell us why.
    </p>
    <h4>Will my personal information and data be kept confidential?</h4>
    <p>
      We will keep your personal information and data confidential and will take reasonable and
      appropriate measures to keep it secure, in accordance with the requirements of the Protection
      of Personal Information Act of 2013 <b>(POPIA)</b> and other applicable legislation. During
      your enrolment period, Quro Medical will act as the responsible party in regard to your data
      and personal information. We will not disclose your data or personal information to any
      unauthorized third party, except to the extent that such disclosure is made in response to a
      specific request by a law enforcement agency with such authority, a subpoena, a court order or
      as otherwise required by law.{' '}
    </p>
    <h4>What will happen to my data?</h4>
    <p>
      Your data will be stored in a secure cloud storage platform that is access controlled by Quro
      Medical. The Platform which stores your information is situatued in North Virginia in the
      United States of America. We are required to notify you of this fact. In this regard, we
      assure you that the cloud is subject to reasonable and appropriate technical safeguards which
      we adhere to, including encryption in transit and multifactor authentication to gain access to
      the database, which is strictly access controlled and limited to authorized personnel only.{' '}
    </p>
    <p>
      As part of the Platform’s remote monitoring component, Quro Medical’s healthcare professionals
      will be able to visualise your data in real time, together with your referring physician. Only
      Quro Medical and your referring physician(s) will have access to your data and personal
      information. It is also possible that we need to share your data with emergency services
      personnel and or hospitals in the event that emergency intervention is required and or
      transfer to a hospital or other health establishment is required. This means we will only tell
      those who have a need or a right to know and we would like your permission to do this.
    </p>
    <p>
      Because technology and analysis tools develop all the time, it isn’t possible to give you an
      exhaustive list of everything that might be done with your data in the future. However, our
      aim is always to work towards the benefit of patients and communities. In doing so, we may use
      your data to work with other hospitals, universities, research institutes, pharmaceutical and
      bio-technology companies, including organisations in other countries to develop predictive
      algorithms and or other useful tools which may be commercialised or used for academic research
      and publication, with a view to improving our technologies, analytic capabilities and overall
      public health.{' '}
      <u>
        However, we won’t share your information with any other organisation unless it is anonymised
      </u>{' '}
      i.e. the information can’t be traced back to you. We would like your permission to do this.
      Additionally, Quro Medical will never sell your personal information to other parties.{' '}
    </p>
    <p>
      If we find out information which has implications for your future health or healthcare, or
      which we believe impacts on your interests, we will inform you. If you would rather not know,
      you have the option not to be told. If, however, your data is fully anonymised, please
      understand that it will no longer be possible to give you feedback about specific results and
      any analysis done will not be available to you.
    </p>
    <h4>Permission to Geolocate</h4>
    <p>
      In order to facilitate timeous intervention in the event of an emergency or in the event that
      transfer to a hospital or other health establishment is required, we would like your
      permission to geolocate you during your enrolment period, using software that is installed in
      the smartphone that will be loaned to you. Only Quro Medical will have access to this
      information but we would like your permission to share this information with your referring
      physician and or emergency services personnel should emergency intervention be required.{' '}
    </p>
    <h4>Your Rights</h4>
    <p>You have the right to:</p>
    <ul>
      <li>
        be notified that your personal information is collected, (note that this is the purpose of
        this Privacy Notice), unless you already have such information or providing such information
        proves impossible, in particular for processing for archiving purposes in public interest,
        scientific or historical research purposes or statistical purposes, subject to the necessary
        safeguards and where appropriate de-identification;{' '}
      </li>
      <li>request access to your personal information held by Quro, as prescribed; </li>
      <li>request that inaccurate personal information related to you is rectified; </li>
      <li>
        request that your personal information held by Quro is erased, subject to the grounds for
        such erasure;{' '}
      </li>
      <li>
        request that the processing of your personal information is restricted in certain
        circumstances;{' '}
      </li>
      <li>
        request that personal information held by Quro be transferred to another Controller, in
        certain circumstances; and{' '}
      </li>
      <li>object to the processing of your personal information in certain circumstances. </li>
      <li>Withdraw the consent for processing from the date we are notified.</li>
    </ul>
    <p>
      Please note: Your rights are not absolute, and may not apply in all cases and we will let you
      know in our correspondence wether we are able to comply with the request and provide reasons
      where we are unable to comply.
    </p>
    <p>
      Should you wish to exercise any of the above rights you may contact the undersigned as
      described hereunder. Should you not be satisfied with the outcome, you also have the right to
      lodge a complaint regarding the processing of your personal information with the Information
      Regulator. In terms of POPIA, you are also entitled to direct a compliant to the Office of the
      Information Regulator, South Africa at SALU Building, 316 Thabo Sehume Street, Pretoria, South
      Africa; Tel: 012 406 4818; Fax: 086 500 3351; Email: inforeg@justice.gov.za; Website:
      http://www.justice.gov.za/inforeg/.
    </p>
    <h4>Instructions for use and handling of wearable devices</h4>
    <p>
      All of our wearable devices are clinical grade and approved by the South African Health
      Products Regulatory Authority <b>(SAHPRA)</b> and or the United States Food and Drug
      Administration <b>(FDA)</b> and or have CE marking. Where appropriate, you will receive
      detailed instructions about the use and handling of these devices. It is hugely important that
      you follow these instructions and ask questions if or when you are unsure. Quro Medical will
      not accept responsibility for any failure to follow the instructions that are given to you.{' '}
    </p>
    <h4>
      What will happen if I lose or damage the wearable devices or equipment that is loaned to me?
    </h4>
    <p>
      You will be held liable for the full costs of replacing any lost or damaged wearable devices
      and/ or equipment that is loaned to you. Please note that you will not be able to recover such
      costs from your medical aid.
    </p>
    <h4>Clinical Services</h4>
    <p>
      The clinical services that are provided to you will be rendered by{' '}
      <i>DRS MHLOMI V AND MODISE G</i> (trading as
      <b>QM Partners</b>) an incorporated practice employing Healthcare Professionals who provide
      clinical services and other healthcare- related services to healthcare patients. Quro Medical
      will be responsible for your overall care coordination during the period of your enrollment on
      the Platform.
    </p>
    <h4>QM Partners Clinical Activities</h4>
    <p>
      Quro Medical receives a referral from your doctor, detailing your relevant clinical
      information, prescription and care plan for your admission to Quro Medical Hospital-at-Home
      (HAH). This information serves as the basis for your continued management while in our care.
      Various physiological parameters are transmitted to QM Partners via the wearable devices that
      are provided to you, and they are visualised remotely in real-time, 24-hours a day by QM
      Partners health professionals. In conjunction with the remote monitoring, the QM Partners
      clinical team will also conduct daily virtual visits for the 5-day admission period. This may
      also be accompanied by home visits (in the instance of HAH admissions), usually for the first
      three days of a five-day Quro Medical HAH admission. The objectives of these home visits are
      to conduct an in-person clinical examination (including assessing chest biosensor location),
      perform procedures/investigations and administer medication as necessary. QM Partners will
      also undertake to explain the need of the medications you are taking and their possible side
      effects and warn you of danger signs to be aware of. Throughout your admission to Quro Medical
      HAH your referring doctor will continue to provide clinical oversight and advise on your
      further management. In the event of clinical deterioration, your referring doctor will be
      notified and should emergency medical services (EMS) be deemed necessary, EMS will be
      dispatched.
    </p>
    <h4>Benefits of HAH</h4>
    <p>
      Quro Medical HAH provides hospital-level care in the comfort of your home. This includes
      conducting clinical examinations, performing procedures/investigations and administering
      medication in your home. It enables continuity of care by allowing your referring physician to
      continue to provide clinical oversight during your Quro Medical HAH admission. The service
      ensures continuous 24-hour vital signs monitoring, facilitating early detection of clinical
      deterioration. It also allows for escalation of care and transition to hospital by dispatching
      EMS providers in the event of clinical deterioration due to progression of disease in the home
      environment. Traditional hospitalisation is associated with the risk of hospital-acquired
      infections, development of new functional impairments and delirium. Home hospitalisation
      mitigates against these risks.
    </p>
    <h4>Limitations of HAH</h4>
    <p>
      Due to the nature of home hospitalisation, the Quro Medical clinical team and your referring
      doctor may not be in close proximity to your home. However, due to the continuous nature of
      the monitoring we are readily able to identify a trend of clinical deterioration and therefore
      activate rapid response protocols timeously. Further to this, the Quro Medical clinical team
      is available 24/7 at our command centre on (010) 141 7710 for any clinical or general queries.
      As your vital signs are visualised remotely and continuously, 24-hours a day, you may be
      contacted intermittently, at any time of the day, should there be difficulty in obtaining your
      clinical data. Please ensure that you provide at least two reliable contact numbers (your own
      and an alternative, ideally someone with whom you live). Additionally, we require you and/ or
      your alternative contacts to be responsive when we contact you. Quro Medical and QM Partners
      cannot accept liability for any negative consequence that may arise from any failure to comply
      with these instructions.
    </p>
    <h4>What if I have any questions or problems?</h4>
    <p>
      For further information, or if you are unhappy with any aspect of the Platform, please
      contact:
    </p>
    <ol>
      <li>
        Dr Luke Sampson, Quro Medical, 24 Peter Place, Lyme Park, Sandton, 2060
        <br />
        Contact No.: +27 10 824 1264
      </li>
    </ol>
  </>
);

const ConsentText = (): JSX.Element => (
  <>
    <h2>INFORMED CONSENT FORM </h2>

    <p> I, the undersigned, hereby acknowledge and consent to the following:</p>
    <p>
      <u>Informed Consent</u>{' '}
    </p>
    <p>
      I confirm that I have read and understood the information leaflet enclosed to this consent
      form and have no objection(s) to any of the contents. Additionally, I agree to give my consent
      where specifically requested.
    </p>
    <p>
      <u>Pricing/ Fees and Payment</u>
    </p>

    <p>
      {' '}
      The fees that Quro Medical charges for the various packages may be requested from Quro
      Medical’s staff. The decision about which package is appropriate for your specific case is
      solely for you and referring physician’s discretion. For monitoring that will depend on the
      duration or intensity of care provided, you may request illustrative values for our estimated
      fees during your enrolment period.{' '}
    </p>

    <p>
      Quro Medical’s fees do not include fees for hospital transfers or subsequent hospitalization.{' '}
    </p>

    <p>
      I understand that my Medical Scheme and plan of choice may or may not cover all of the fees
      charged by Quro Medical and I understand that it is my responsibility to contact my Medical
      Aid and or Medical Insurance to obtain information about which benefits my specific plan
      includes or excludes.{' '}
    </p>

    <p>
      I understand that I am fully responsible and liable for any fees that have not been fully
      settled by my Medical Scheme and or Medical Insurance and that I will be liable for debt
      recovery costs should I fail to pay timeously.
    </p>

    <p>
      I understand that my referring physician and or practice may need to disclose information
      about my ICD – diagnosis code(s) to Quro Medical and or my Medical Scheme where appropriate
      and I grant my permission to do so.
    </p>

    <p>
      By enrolling on the Platform, I consent to Quro Medical submitting my account(s) to my Medical
      Scheme and or Medical Insurance. This does not mean that the Medical Scheme and or Medical
      Insurance has received the account or accepts liability for the account and it is my
      responsibility to confirm this with my Medical Scheme.
    </p>

    <p>
      <u>Loss of Equipment/ Wearable Devices</u>
    </p>

    <p>
      I understand that the non – disposable wearable devices and/ or equipment have been loaned to
      me and are returnable to Quro Medical upon the conclusion of my monitoring period. In this
      regard, I understand that I will be liable for any loss, theft or damage of these non –
      disposable wearable devices and/ or equipment.
    </p>

    <p>
      <u>No Refund</u>
    </p>

    <p>
      I understand that although I may withdraw my consent at any time after the commencement of my
      enrolment, Quro Medical’s fees will still be due and payable in full and any amounts that I
      have already paid will be non – refundable.
    </p>

    <p>
      <u>Accuracy and Correctness of Personal Information</u>
    </p>

    <p>
      I confirm that the information that I have provided is true and correct and I hereby indemnify
      and hold Quro Medical and or my referring physician harmless for any loss or harm resulting
      from any incomplete, untrue or inaccurate information provided by me.
    </p>

    <p>
      <u>Equipment and accuracy of data</u>
    </p>

    <p>
      {' '}
      I understand that Quro Medical has used its best endevours to ensure that disposables,
      equipment and or wearable devices and other technology that is used for my monitoring are in
      good order and fit for purpose. However, I also understand that it is in the nature of
      healthcare and technology that the precise accuracy of data and reliability of connectivity
      cannot be guaranteed and nothing in this form or material provided to me consists of an
      express or implied warranty in this regard. I understand that my own behaviour may affect the
      quality of the data and services that I receive. I agree to follow the instructions given by
      my referring physician and Quro Medical’s healthcare professionals. Should I fail to do this,
      I agree to indemnify and hold my referring physician and Quro Medical harmless for any
      negative consequence.{' '}
    </p>
  </>
);

export { InformationText, ConsentText };
